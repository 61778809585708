import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIpContext } from '@lib/ipContext';
import cx from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';

const PrimaryContact = () => {
  const { ipState, dispatch } = useIpContext();
  const [contactDetails, setContactDetails] = useState(ipState.contactDetails);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const newContactDetails = { ...contactDetails, [name]: value };
    dispatch({ type: 'updateContactDetails', payload: newContactDetails });
  };

  const handleSignup = (e) => {
    dispatch({ type: 'updateCmnzSignup', payload: e.target.checked });
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = () => {
    console.log('submitted');
    dispatch({ type: 'updateCurrentStep', payload: 2 });
  };

  const roles = [
    'Member of the Group',
    'Music Tutor',
    'School Teacher',
    'Parent',
    'Other',
  ];

  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
        className='entry-step-container'>
        <div className='xl:grid grid-cols-12'>
          <div className='col-start-1 col-end-6  entry-title-container  xl:sticky xl:top-5 xl:self-start'>
            <h4 className='blockH4 mb-2'>Primary Contact Details</h4>
            <p>This will be the person who receives all contest information</p>
          </div>
          <div className='col-start-8 col-span-full mb-10 xl:mt-5'>
            <form onSubmit={handleSubmit(onSubmit)} id='ip-contact-form'>
              <div class=' entryInputContainer group'>
                <input
                  type='text'
                  placeholder=' '
                  className={cx('entryInput peer ', {
                    'focus:border-sand border-sand ':
                      errors.primaryFullName?.type !== 'required',
                    'focus:border-orange border-orange ':
                      errors.primaryFullName?.type === 'required',
                  })}
                  value={ipState?.contactDetails?.primaryFullName}
                  {...register('primaryFullName', {
                    onChange: handleInputChange,
                    required: true,
                    setValueAs: (value) => value,
                  })}
                />
                <label
                  for='primaryFullName'
                  className='entryLabel pointer-events-none '>
                  Full Name*
                </label>
                {errors.primaryFullName?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>
              <div class=' entryInputContainer group'>
                <input
                  type='text'
                  placeholder=' '
                  className={cx('entryInput peer ', {
                    'focus:border-sand border-sand ':
                      errors.primaryPreferredName?.type !== 'required',
                    'focus:border-orange border-orange ':
                      errors.primaryPreferredName?.type === 'required',
                  })}
                  value={ipState?.contactDetails?.primaryPreferredName}
                  {...register('primaryPreferredName', {
                    onChange: handleInputChange,
                    required: true,
                    setValueAs: (value) => value,
                  })}
                />
                <label
                  for='primaryPreferredName'
                  className='entryLabel pointer-events-none '>
                  Preferred first name?*
                </label>
                {errors.primaryPreferredName?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>
              <div className='relative'>
                {ipState?.contactDetails?.primaryRole && (
                  <motion.span
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className='selectLabel '>
                    I am a*
                  </motion.span>
                )}
                <select
                  id=''
                  className={cx('entrySelect', {
                    'focus:border-sand border-sand':
                      errors.primaryRole?.type !== 'required',
                    'focus:border-orange border-orange':
                      errors.primaryRole?.type === 'required',
                  })}
                  value={ipState?.contactDetails?.primaryRole}
                  {...register('primaryRole', {
                    onChange: handleInputChange,
                    required: true,
                    setValueAs: (value) => value,
                  })}>
                  <option value='' disabled selected>
                    I am a*
                  </option>
                  {roles.map((region) => (
                    <option value={region}>{region}</option>
                  ))}
                </select>
                {errors.primaryRole?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>

              <div class=' entryInputContainer group'>
                <input
                  type='text'
                  placeholder=' '
                  className={cx('entryInput peer ', {
                    'focus:border-sand border-sand':
                      errors.primaryPhone?.type !== 'required',
                    'focus:border-orange border-orange':
                      errors.primaryPhone?.type === 'required',
                  })}
                  value={ipState?.contactDetails?.primaryPhone}
                  {...register('primaryPhone', {
                    onChange: handleInputChange,
                    required: true,
                    setValueAs: (value) => value,
                  })}
                />
                <label
                  for='primaryPhone'
                  className='entryLabel pointer-events-none '>
                  Mobile Phone*
                </label>
                {errors.primaryPhone?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>

              <div class=' entryInputContainer group'>
                <input
                  type='text'
                  placeholder=' '
                  className={cx('entryInput peer ', {
                    'focus:border-sand border-sand':
                      errors.primaryEmail?.type !== 'required',
                    'focus:border-orange border-orange':
                      errors.primaryEmail?.type === 'required',
                  })}
                  value={ipState?.contactDetails?.primaryEmail}
                  {...register('primaryEmail', {
                    onChange: handleInputChange,
                    required: true,
                    setValueAs: (value) => value,
                  })}
                />
                <label
                  for='primaryEmail'
                  className='entryLabel pointer-events-none '>
                  Email Address*
                </label>
                {errors.primaryEmail?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>

              <div class=' entryInputContainer group'>
                <input
                  type='text'
                  placeholder=' '
                  className={cx('entryInput peer ', {
                    'focus:border-sand border-sand':
                      errors.primaryAddress?.type !== 'required',
                    'focus:border-orange border-orange':
                      errors.primaryAddress?.type === 'required',
                  })}
                  value={ipState?.contactDetails?.primaryAddress}
                  {...register('primaryAddress', {
                    onChange: handleInputChange,
                    required: true,
                    setValueAs: (value) => value,
                  })}
                />
                <label
                  for='primaryAddress'
                  className='entryLabel pointer-events-none '>
                  Address*
                </label>
                {errors.primaryAddress?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>

              <div class=' entryInputContainer group'>
                <input
                  type='text'
                  placeholder=' '
                  className={cx('entryInput peer ', {
                    'focus:border-sand border-sand':
                      errors.primarySuburb?.type !== 'required',
                    'focus:border-orange border-orange':
                      errors.primarySuburb?.type === 'required',
                  })}
                  value={ipState?.contactDetails?.primarySuburb}
                  {...register('primarySuburb', {
                    onChange: handleInputChange,
                    required: true,
                    setValueAs: (value) => value,
                  })}
                />
                <label
                  for='primarySuburb'
                  className='entryLabel pointer-events-none '>
                  Suburb*
                </label>
                {errors.primarySuburb?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>

              <div class=' entryInputContainer group'>
                <input
                  type='text'
                  placeholder=' '
                  className={cx('entryInput peer ', {
                    'focus:border-sand border-sand':
                      errors.primaryCity?.type !== 'required',
                    'focus:border-orange border-orange':
                      errors.primaryCity?.type === 'required',
                  })}
                  value={ipState?.contactDetails?.primaryCity}
                  {...register('primaryCity', {
                    onChange: handleInputChange,
                    required: true,
                    setValueAs: (value) => value,
                  })}
                />
                <label
                  for='primaryCity'
                  className='entryLabel pointer-events-none '>
                  City*
                </label>
                {errors.primaryCity?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>

              <div class=' entryInputContainer group'>
                <input
                  type='text'
                  placeholder=' '
                  className={cx('entryInput peer ', {
                    'focus:border-sand border-sand':
                      errors.primaryTown?.type !== 'required',
                    'focus:border-orange border-orange':
                      errors.primaryTown?.type === 'required',
                  })}
                  value={ipState?.contactDetails?.primaryTown}
                  {...register('primaryTown', {
                    onChange: handleInputChange,
                    required: true,
                    setValueAs: (value) => value,
                  })}
                />
                <label
                  for='primaryTown'
                  className='entryLabel pointer-events-none '>
                  Town*
                </label>
                {errors.primaryTown?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>

              <div class=' entryInputContainer group'>
                <input
                  type='number'
                  placeholder=' '
                  className={cx('entryInput peer ', {
                    'focus:border-sand border-sand':
                      errors.primaryPostcode?.type !== 'required',
                    'focus:border-orange border-orange':
                      errors.primaryPostcode?.type === 'required',
                  })}
                  value={ipState?.contactDetails?.primaryPostcode}
                  {...register('primaryPostcode', {
                    onChange: handleInputChange,
                    required: true,
                    valueAsNumber: true,
                    setValueAs: (value) => value,
                  })}
                />
                <label
                  for='primaryPostcode'
                  className='entryLabel pointer-events-none '>
                  Postcode*
                </label>
                {errors.primaryPostcode?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>

              <div className='relative'>
                <label
                  className={
                    'radioContainer flex gap-[20px] items-start mb-3 xl:gap-[25px]'
                  }>
                  <input
                    type={'checkbox'}
                    // name="rulesAndConditions"
                    // onChange={handleRulesAccepted}
                    value={ipState?.cmnzSignup}
                    checked={ipState?.cmnzSignup}
                    {...register('cmnzSignup', {
                      onChange: handleSignup,
                      setValueAs: (value) => value,
                      checked: ipState?.cmnzSignup,
                    })}
                  />
                  <span
                    className={
                      'checkmark rounded-full w-[22px] h-[22px] bg-[rgba(0,0,0,0)] border border-white block xl:w-[26px] xl:h-[26px] cursor-pointer flex-none'
                    }></span>
                  <span className={'cursor-pointer blockH8'}>
                    Yes, I would like to sign up to CMNZ email list.
                  </span>
                </label>
              </div>
            </form>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default PrimaryContact;
