import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useIpContext } from '@lib/ipContext';

import Chevron from '@svg/chevron-down-white.svg';

const SummaryItem = ({
  active,
  setActive,
  index,
  groupName,
  groupMembersArray,
  groupTutorName,
  musicPiecesArray,
}) => {
  const isActive = active === index;
  const handleClick = () => (!isActive ? setActive(index) : setActive(null));

  return (
    <div className='w-full h-fit lg:h-full  '>
      <div
        className='flex justify-between items-center cursor-pointer  pb-2 border-opacity-[30%] border-b border-sand'
        onClick={handleClick}>
        <div className='flex justify-between w-full'>
          <h1 className='blockH6'>{groupName}</h1>
          <motion.div
            initial={{ rotate: 0 }}
            animate={{ rotate: isActive ? 180 : 0 }}
            transition={{
              duration: 0.5,
            }}
            className='flex items-center'>
            <Chevron className='svg-sand w-5 h-auto self-center' />
          </motion.div>
        </div>
      </div>

      <div className=' w-full h-[2px] '></div>
      <AnimatePresence>
        {isActive && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            className='overflow-hidden'
            transition={{
              duration: 0.5,
            }}>
            <div className='lg:grid grid-cols-12 mt-2 space-y-8 lg:space-y-0 pb-10 sm:pb-20 xl:pb-36 pt-4 '>
              <div className='col-start-1 col-end-4 space-y-2'>
                <h6>Members: </h6>
                {groupMembersArray?.map((member, index) => (
                  <p key={index}>{member?.memberName}</p>
                ))}
              </div>
              <div className='col-start-5 col-end-8 space-y-2'>
                <h6>Music: </h6>
                {musicPiecesArray?.map((piece, index) => (
                  <p key={index}>
                    <span>{piece?.musicTitle}</span> -{' '}
                    {piece?.musicMovements && (
                      <span>{piece?.musicMovements} - </span>
                    )}
                    <span>{piece?.musicComposer}</span>
                  </p>
                ))}
              </div>
              <div className='col-start-9 col-end-12 space-y-2'>
                <h6>Teacher/Tutor: </h6>
                <p>{groupTutorName}</p>
              </div>
            </div>

            <div className=' w-full h-3'></div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const InstrumentalEntrySummary = () => {
  const { ipState } = useIpContext();
  const [active, setActive] = useState(0);

  return (
    <div className='entry-step-container'>
      <div className='flex justify-between blockH6 mb-14 '>
        <h4>{`${ipState.groups.length} x Instrumental Performance`}</h4>
        {/* <p>{`$${ipState.groups.length * 100}.00`}</p> */}
      </div>
      <div className='flex flex-col space-y-[20px] mt-[70px]'>
        {ipState?.groups?.map((group, index) => (
          <SummaryItem
            {...group}
            index={index}
            active={active}
            setActive={setActive}
          />
        ))}
      </div>
      <div className='flex justify-end pt-12 mt-40 border-t border-sand'>
        {/* <p className="space-x-20 blockH6">
          <span>Total:</span>
          <span>{`$${ipState.groups.length * 100}.00`}</span>
        </p> */}
      </div>
    </div>
  );
};

export default InstrumentalEntrySummary;
