import React from 'react';
import { useForm } from 'react-hook-form';
import { useIpContext } from '@lib/ipContext';
import GroupSidebar from '../groupSidebar';
import cx from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';

const MemberInfo = ({ groupInformation, setGroupInformation }) => {
  const { ipState, dispatch } = useIpContext();

  const handleMemberDetails = (e) => {
    const { name, value } = e.target;
    const newMemberDetails = {
      ...groupInformation,
      groupMembersArray: groupInformation.groupMembersArray.map((member, i) => {
        if (i === groupInformation.currentMember) {
          return {
            ...member,
            [name]: value,
          };
        }
        return member;
      }),
    };
    dispatch({ type: 'updateGroup', payload: newMemberDetails });
    setGroupInformation(newMemberDetails);
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = () => {
    if (
      ipState.currentGroup === ipState.groups.length - 1 &&
      groupInformation.currentMember === groupInformation.groupMembers - 1
    ) {
      const newGroupInformation = {
        ...groupInformation,
        groupMembersArray: groupInformation.groupMembersArray.map(
          (member, i) => {
            if (i === groupInformation.currentMember) {
              return {
                ...member,
                memberComplete: true,
              };
            }
            return member;
          },
        ),
      };
      dispatch({ type: 'updateGroup', payload: newGroupInformation });
      dispatch({ type: 'updateCurrentStep', payload: 5 });
      setGroupInformation(newGroupInformation);
    } else if (
      groupInformation.currentMember ===
      groupInformation.groupMembers - 1
    ) {
      const newGroupInformation = {
        ...groupInformation,
        groupMembersArray: groupInformation.groupMembersArray.map(
          (member, i) => {
            if (i === groupInformation.currentMember) {
              return {
                ...member,
                memberComplete: true,
              };
            }
            return member;
          },
        ),
      };
      dispatch({ type: 'updateGroup', payload: newGroupInformation });
      dispatch({
        type: 'updateCurrentGroup',
        payload: ipState.currentGroup + 1,
      });
      setGroupInformation(newGroupInformation);
    } else {
      const newGroupInformation = {
        ...groupInformation,
        currentMember: groupInformation.currentMember + 1,
        groupMembersArray: groupInformation.groupMembersArray.map(
          (member, i) => {
            if (i === groupInformation.currentMember) {
              return {
                ...member,
                memberComplete: true,
              };
            }
            return member;
          },
        ),
      };
      dispatch({ type: 'updateGroup', payload: newGroupInformation });
      setGroupInformation(newGroupInformation);
    }
  };

  const composerYears = [
    'Year 7',
    'Year 8',
    'Year 9',
    'Year 10',
    'Year 11',
    'Year 12',
    'Year 13',
  ];

  const composerEthnicities = [
    'NZ European',
    'Māori',
    'Pasifika',
    'Asian',
    'Middle Eastern',
    'Latin American',
    'African',
    'Other',
  ];

  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
        className='entry-step-container'>
        <div className='xl:grid grid-cols-12'>
          <GroupSidebar
            currentGroup={ipState.currentGroup}
            groupInformation={groupInformation}
            setGroupInformation={setGroupInformation}
            groups={ipState?.groups}
          />
          <div className='col-start-8 col-span-full mb-10 xl:mt-5'>
            <form onSubmit={handleSubmit(onSubmit)} id='ip-group-info'>
              <h5 className='blockH6 mb-9'>{`Member ${
                groupInformation.currentMember + 1
              } Information`}</h5>
              <div class=' entryInputContainer group'>
                <input
                  type='text'
                  placeholder=' '
                  className={cx('entryInput peer ', {
                    'focus:border-sand border-sand ':
                      errors.memberName?.type !== 'required',
                    'focus:border-orange border-orange ':
                      errors.memberName?.type === 'required',
                  })}
                  value={
                    groupInformation?.groupMembersArray[
                      groupInformation?.currentMember
                    ]?.memberName
                  }
                  {...register('memberName', {
                    onChange: handleMemberDetails,
                    required: true,
                    setValueAs: (value) => value,
                  })}
                />
                <label
                  for='memberName'
                  className='entryLabel pointer-events-none '>
                  Full Name*
                </label>
                {errors.memberName?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>
              <div class=' entryInputContainer group'>
                <input
                  type='text'
                  placeholder=' '
                  className={cx('entryInput peer ', {
                    'focus:border-sand border-sand ':
                      errors.memberPreferredName?.type !== 'required',
                    'focus:border-orange border-orange ':
                      errors.memberPreferredName?.type === 'required',
                  })}
                  value={
                    groupInformation?.groupMembersArray[
                      groupInformation?.currentMember
                    ]?.memberPreferredName
                  }
                  {...register('memberPreferredName', {
                    onChange: handleMemberDetails,
                    required: true,
                    setValueAs: (value) => value,
                  })}
                />
                <label
                  for='memberPreferredName'
                  className='entryLabel pointer-events-none '>
                  Preferred first name?*
                </label>
                {errors.memberPreferredName?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>
              <div class=' entryInputContainer group'>
                <input
                  type='text'
                  placeholder=' '
                  className={cx('entryInput peer ', {
                    'focus:border-sand border-sand ':
                      errors.memberEmail?.type !== 'required',
                    'focus:border-orange border-orange ':
                      errors.memberEmail?.type === 'required',
                  })}
                  value={
                    groupInformation?.groupMembersArray[
                      groupInformation?.currentMember
                    ]?.memberEmail
                  }
                  {...register('memberEmail', {
                    onChange: handleMemberDetails,
                    required: true,
                    setValueAs: (value) => value,
                  })}
                />
                <label
                  for='memberEmail'
                  className='entryLabel pointer-events-none '>
                  Email Address*
                </label>
                {errors.memberEmail?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>
              <div class=' entryInputContainer group'>
                <input
                  type='text'
                  placeholder=' '
                  className={cx('entryInput peer ', {
                    'focus:border-sand border-sand ':
                      errors.instrumentVoice?.type !== 'required',
                    'focus:border-orange border-orange ':
                      errors.instrumentVoice?.type === 'required',
                  })}
                  value={
                    groupInformation?.groupMembersArray[
                      groupInformation?.currentMember
                    ]?.instrumentVoice
                  }
                  {...register('instrumentVoice', {
                    onChange: handleMemberDetails,
                    required: true,
                    setValueAs: (value) => value,
                  })}
                />
                <label
                  for='instrumentVoice'
                  className='entryLabel pointer-events-none '>
                  Instrument/Voice*
                </label>
                {errors.instrumentVoice?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>

              <div class=' entryInputContainer group'>
                <input
                  type='text'
                  placeholder=' '
                  className={cx('entryInput peer ', {
                    'focus:border-sand border-sand ':
                      errors.townCity?.type !== 'required',
                    'focus:border-orange border-orange ':
                      errors.townCity?.type === 'required',
                  })}
                  value={
                    groupInformation?.groupMembersArray[
                      groupInformation?.currentMember
                    ]?.townCity
                  }
                  {...register('townCity', {
                    onChange: handleMemberDetails,
                    required: true,
                    setValueAs: (value) => value,
                  })}
                />
                <label
                  for='townCity'
                  className='entryLabel pointer-events-none '>
                  Town/City of Residence*
                </label>
                {errors.townCity?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>
              <div class=' entryInputContainer group'>
                <input
                  type='text'
                  placeholder=' '
                  className={cx('entryInput peer ', {
                    'focus:border-sand border-sand ':
                      errors.memberSchool?.type !== 'required',
                    'focus:border-orange border-orange ':
                      errors.memberSchool?.type === 'required',
                  })}
                  value={
                    groupInformation?.groupMembersArray[
                      groupInformation?.currentMember
                    ]?.memberSchool
                  }
                  {...register('memberSchool', {
                    onChange: handleMemberDetails,
                    required: true,
                    setValueAs: (value) => value,
                  })}
                />
                <label
                  for='memberSchool'
                  className='entryLabel pointer-events-none '>
                  School Name*
                </label>
                {errors.memberSchool?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>
              <div className='relative'>
                {groupInformation?.groupMembersArray[
                  groupInformation?.currentMember
                ]?.memberSchoolYear !== '' && (
                  <motion.span
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className='selectLabel '>
                    What year are you currently at school?*
                  </motion.span>
                )}
                <select
                  id=''
                  className={cx('entrySelect', {
                    'focus:border-sand border-sand':
                      errors.memberSchoolYear?.type !== 'required',
                    'focus:border-orange border-orange':
                      errors.memberSchoolYear?.type === 'required',
                  })}
                  value={
                    groupInformation?.groupMembersArray[
                      groupInformation?.currentMember
                    ]?.memberSchoolYear
                  }
                  {...register('memberSchoolYear', {
                    onChange: handleMemberDetails,
                    required: true,
                    setValueAs: (value) => value,
                  })}>
                  <option value='' disabled selected>
                    What year are you currently at school?*
                  </option>
                  {composerYears.map((year) => (
                    <option value={year}>{year}</option>
                  ))}
                </select>
                {errors.memberSchoolYear?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>
              <div className='relative'>
                {groupInformation?.groupMembersArray[
                  groupInformation?.currentMember
                ]?.memberEthnicities !== '' && (
                  <motion.span
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className='selectLabel '>
                    Ethnicity*
                  </motion.span>
                )}
                <select
                  id=''
                  className={cx('entrySelect', {
                    'focus:border-sand border-sand':
                      errors.memberEthnicities?.type !== 'required',
                    'focus:border-orange border-orange':
                      errors.memberEthnicities?.type === 'required',
                  })}
                  value={
                    groupInformation?.groupMembersArray[
                      groupInformation?.currentMember
                    ]?.memberEthnicities
                  }
                  {...register('memberEthnicities', {
                    onChange: handleMemberDetails,
                    required: true,
                    setValueAs: (value) => value,
                  })}>
                  <option value='' disabled selected>
                    Ethnicity*
                  </option>
                  {composerEthnicities.map((region) => (
                    <option value={region}>{region}</option>
                  ))}
                </select>
                {errors.memberEthnicities?.type === 'required' && (
                  <p className='entryError'>Field is required</p>
                )}
              </div>
              <div class=' entryInputContainer group'>
                <input
                  type='text'
                  placeholder=' '
                  className={cx(
                    'entryInput peer focus:border-sand border-sand ',
                    {},
                  )}
                  value={
                    groupInformation?.groupMembersArray[
                      groupInformation?.currentMember
                    ]?.composerOtherEthnicities
                  }
                  {...register('composerOtherEthnicities', {
                    onChange: handleMemberDetails,
                    setValueAs: (value) => value,
                  })}
                />
                <label
                  for='composerOtherEthnicities'
                  className='entryLabel pointer-events-none '>
                  Other ethnicities (if applicable)
                </label>
              </div>
            </form>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default MemberInfo;
