import React, { useReducer, createContext, useEffect } from 'react';

const musicPieceObject = {
  musicTitle: '',
  musicMovements: '',
  musicComposer: '',
  musicDuration: '',
};

const memberObject = {
  memberName: '',
  memberPreferredName: '',
  memberEmail: '',
  instrumentVoice: '',
  townCity: '',
  memberSchool: '',
  memberSchoolYear: '',
  memberEthnicities: '',
  composerOtherEthnicities: '',
  memberComplete: false,
};

const groupObject = {
  groupName: '',
  groupTutorName: '',
  groupTutorPhone: '',
  groupTutorEmail: '',
  groupConditions: false,
  groupMembers: '3',
  groupMembersArray: [
    { ...memberObject },
    { ...memberObject },
    { ...memberObject },
  ],
  groupDetailsComplete: false,
  membersComplete: false,
  currentMember: 0,
  originalComposition: false,
  originalCompositionTitle: '',
  originalCompositionComposer: '',
  requiresPiano: false,
  requiresMorePianos: false,
  nzWork: false,
  freeCoaching: false,
  coachingDate: [],
  musicPieces: '1',
  musicPiecesArray: [{ ...musicPieceObject }],
};

const initialState = {
  contactDetails: {
    // composerFullName: "",
    // composerPreferredName: "",
    // composerRegion: "",
    // composerSchool: "",
    // composerSchoolYear: "",
    // composerSchoolAge: "",
    // composerEthnicities: "",
    // composerOtherEthnicities: "",
    // composerAddress: "",
    // composerSuburb: "",
    // composerCity: "",
    // composerTown: "",
    // composerPostcode: "",
    // composerPhone: "",
    // composerEmail: "",
  },
  billingDetails: {
    // originalBillingName: "",
    // originalBillingSchool: "",
    // originalBillingEmail: "",
    // originalBillingAddress: "",
    // originalBillingSuburb: "",
    // originalBillingCity: "",
    // originalBillingTown: "",
    // originalBillingPostcode: "",
  },
  regionGroup: {},
  groups: [],
  currentGroup: 0,
  stateToggle: false,
  rulesAndConditions: false,
  cmnzSignup: false,
  currentStep: 1,
};

export const IpContext = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case 'loadState':
      return action.payload;
    case 'updateContactDetails':
      return {
        ...state,
        contactDetails: {
          ...state.contactDetails,
          ...action.payload,
        },
      };
    case 'updateBillingDetails':
      return {
        ...state,
        billingDetails: action.payload,
      };
    case 'updateRulesAndConditions':
      return {
        ...state,
        rulesAndConditions: action.payload,
      };
    case 'updateCmnzSignup':
      return {
        ...state,
        cmnzSignup: action.payload,
      };
    case 'updateRegionGroup':
      return {
        ...state,
        regionGroup: action.payload,
        currentGroup: 0,
        // add the amount of instrumentalGroups number of objects with the groupObject template to the groups array, with 'groupName: "Group {index + 1}"'
        groups: Array.from(
          { length: action.payload.instrumentalGroups },
          (v, i) => ({ ...groupObject, groupName: `Group ${i + 1}` }),
        ),
      };
    case 'updateGroup':
      // update only the group object at the index of the currentGroup in the groups array
      return {
        ...state,
        groups: state.groups.map((group, index) => {
          if (index === state.currentGroup) {
            return action.payload;
          }

          return group;
        }),
      };
    case 'updateCurrentGroup':
      return {
        ...state,
        currentGroup: action.payload,
      };
    case 'updateCurrentStep':
      return {
        ...state,
        currentStep: action.payload,
      };
    case 'updateGroupNav':
      return {
        ...state,
        currentGroup: action.payload,
        // toggle stateToggle
        stateToggle: !state.stateToggle,

        // set current groupDetailsComplete to false
        groups: state.groups.map((group, index) => {
          if (index === action.payload) {
            return {
              ...group,
              groupDetailsComplete: false,
            };
          }

          return group;
        }),
      };

    case 'updateMemberGroupNav':
      return {
        ...state,
        currentGroup: action.payload,
      };
    case 'updateMemberNav':
      // update the currentMember index in the currentGroup object
      return {
        ...state,
        groups: state.groups.map((group, index) => {
          if (index === state.currentGroup) {
            return {
              ...group,
              currentMember: action.payload,
            };
          }

          return group;
        }),
      };

    // reset the state to the initialState
    case 'resetState':
      return initialState;

    default:
      throw new Error('Invalid action type');
  }
};

const StateProvider = ({ children }) => {
  const [ipState, dispatch] = useReducer(reducer, initialState);

  // load state from localstorage on first render
  useEffect(() => {
    const localState = localStorage.getItem('ipState');
    if (localState) {
      dispatch({ type: 'loadState', payload: JSON.parse(localState) });
    }
  }, []);

  // update browser localstorage as state changes
  useEffect(() => {
    localStorage.setItem('ipState', JSON.stringify(ipState));
  }, [ipState]);

  return (
    <IpContext.Provider value={{ ipState, dispatch }}>
      {children}
    </IpContext.Provider>
  );
};

const useIpContext = () => {
  const context = React.useContext(IpContext);
  if (context === undefined) {
    throw new Error(`useSiteContext must be used within a SiteProvider`);
  }
  return context;
};

export { StateProvider, useIpContext };
